import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";

import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { useTrans } from "../hooks";

const NotFound = ({ pageContext }) => {
  const { seoData, layoutProps } = pageContext;
  const transMsg = useTrans();
  return (
    <Layout seo={seoData} showCart {...layoutProps}>
      <section className="section pt-5 pb-5 osahan-not-found-page">
        <Container>
          <Row>
            <Col md={12} className="text-center pt-5 pb-5">
              <StaticImage
                className="img-fluid"
                src="../images/404.png"
                alt="404"
              />
              <h1 className="mt-2 mb-2">{transMsg("notFound")} </h1>
              <p>
                {transMsg("notFoundContent1")}
                <br />
                {transMsg("notFoundContent2")}
              </p>
              <Link className="btn btn-primary btn-lg" to="/">
                {transMsg("goHome")}
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default NotFound;
